import { template as template_be428c1b43c84193a734596f3ec48e73 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_be428c1b43c84193a734596f3ec48e73(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
