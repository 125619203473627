import { template as template_cfdab3a4131d47c1a7142cf6d92f5f62 } from "@ember/template-compiler";
const WelcomeHeader = template_cfdab3a4131d47c1a7142cf6d92f5f62(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
